<template>
  <div style="margin: 0 auto; text-align: center">
    <h1 style="margin-top: 40px; font-size: 32px; color: #2d3640; font-weight: 400;">Kullanıcı artık aktif değil.</h1>

    <div class="hiddendiv"></div>

    <img style="height: 410px;" src="https://gcdn.bionluk.com/site/general/deactive_user.png"/>


  </div>
</template>

<script>


  export default {
    name: 'src-pages-body-error-page-not-found-v1',
    data() {
      return {
        loadingAnimation: true
      }
    },

    methods: {},


    created() {

      let meta = document.createElement('meta');
      meta.name = "render:status_code";
      meta.content = "301";
      document.getElementsByTagName('head')[0].appendChild(meta);


      //let meta2 = document.createElement('meta');
      //let meta2content = "0,url=https://bionluk.com" + metaLocation;
      //meta2.setAttribute("http-equiv", "refresh");
      //meta2.setAttribute("content", meta2content);
      //document.getElementsByTagName('head')[0].appendChild(meta2);
      let meta3 = document.createElement('meta');
      let meta3content = "Location:https://bionluk.com";
      meta3.setAttribute("name", "render:header");
      meta3.setAttribute("content", meta3content);
      document.getElementsByTagName('head')[0].appendChild(meta3);



      this.$toasted.global.errorToast({description: "Aradığın kullanıcının hesabı artık aktif değil."});
      this.$router.push('');

    }
  }
</script>
